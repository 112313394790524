<template>
  <div v-loading="loading">
    <pdf
      v-for="i in numPages"
      ref="pdf"
      :key="i"
      :src="src"
      :page="i"
    />
  </div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
  name: 'Pdf',
  components: {
    pdf
  },
  props: {
    url: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      src: pdf.createLoadingTask(this.url),
      numPages: null, // pdf 总页数
      loading: true, // pdf 总页数
    }
  },
  computed: {
  },
  mounted() {
    this.getNumPages()
  },
  methods: {
    getNumPages() {
      this.src.promise.then(pdf => {
        this.numPages = pdf.numPages
      }).catch(err => {
        console.error('pdf 加载失败', err)
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style scoped lang="less">
/*@import '~@/styles/variables.less';*/

</style>
