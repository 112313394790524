<template>
  <div class="lawyerDetails">
    <Breadcrumb :data="breData" />
    <div v-if="$route.query.type === 'LAWS'" class="content">
      <div class="title">
        {{ lawRegulationsDetails.title | textFilter }}
      </div>
      <div class="item">
        <div class="tag">基本信息</div>
        <div class="container">
          <div v-for="(item, index) of labelData" :key="index" class="uiText">
            <div>{{ item.label }}:</div>
            <div class="des">
              {{ handleVal(item) | textFilter }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="lawRegulationsDetails.brief_introduction" class="item">
        <div class="tag">法规详情</div>
        <div class="container">
          <div class="uiText">
            <div
              class="lawConntent"
              v-html="lawRegulationsDetails.brief_introduction"
            ></div>
          </div>
        </div>
      </div>
      <div v-if="lawRegulationsDetails.content_html" class="item">
        <div class="tag">法规正文</div>
        <div class="container">
          <div class="uiText">
            <div class="lawText" v-html="lawRegulationsDetails.content_html"></div>
          </div>
        </div>
      </div>
      <Pdf v-if="lawRegulationsDetails.pdf_url" :url="lawRegulationsDetails.pdf_url" class="item" />
      <Pdf v-if="lawRegulationsDetails.pdf_url_qn" :url="lawRegulationsDetails.pdf_url_qn" class="item" />
    </div>
    <div v-else class="content content1">
      <div class="title">
        <el-button v-show="lawRegulationsDetails.caseReason" class="analysis" type="primary" @click="jumpCaseAnalysis">
          案例分析
        </el-button>
        {{ lawRegulationsDetails.title | textFilter }}
      </div>
      <div class="item">
        <div class="tag">基本信息</div>
        <div class="container">
          <div v-for="(item, index) of labelData" :key="index" class="uiText">
            <div>{{ item.label }}:</div>
            <div class="des">
              {{ handleItme(lawRegulationsDetails[item.key]) | textFilter }}
            </div>
          </div>
        </div>
      </div>
      <div class="detailsHtml" v-html="lawRegulationsDetails.detailsHtml"></div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import Pdf from '@/components/Pdf'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'LawyerDetails',
  components: {
    Breadcrumb,
    Pdf,
  },
  data() {
    return {
      labelData: [
        {
          label: '法律',
          key: 'category',
        },
        {
          label: '时效性',
          key: 'effectiveness',
        },
        {
          label: '发布日期',
          key: 'issue_date',
        },
        {
          label: '实施日期',
          key: 'implementation_date',
        },
        {
          label: '发文字号',
          key: '1',
        },
        {
          label: '发布机关',
          key: 'release_department',
        },
      ],
      breData: [
        {
          name: '法律查询',
          path: '/legal/query',
        },
        {
          name: '法律法规',
          path: '/legal/query',
        },
        {
          name: '法律法规详情页',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['lawRegulationsDetails']),
  },
  created() {
    this.getData()
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.changeDefalutData()
    })
  },
  methods: {
    ...mapActions(['getLawRegulationsDetails', 'getJudicialCaseDetails']),
    handleVal(item) {
      const { country } = this.$route.query
      let key = item.key
      if (country === 'UZBEKISTAN') {
        if (key === 'issue_date') {
          key = 'implementation_date'
        } else if (key === 'implementation_date') {
          key = 'issue_date'
        }
      }
      return this.lawRegulationsDetails[key]
    },
    jumpCaseAnalysis() {
      // const { type, id, country } = this.$route.query
      const caseReason = this.lawRegulationsDetails.caseReason
      if (caseReason) {
        this.$router.push({
          path: '/legal/analysis',
          query: {
            caseReason,
          }
        })
      } else {
        this.$message.error('案由不能为空!')
      }
    },
    handleItme(val) {
      if (Array.isArray(val)) {
        val = val.join(', ')
      }
      return val
    },
    getData() {
      const { type, id, country } = this.$route.query
      const cod = !((country === 'CHINA' || country === '中国' || !country))
      if (type === 'LAWS') {
        this.getLawRegulationsDetails({
          id,
          flag: cod,
        })
      } else if (type === 'CASE') {
        this.getJudicialCaseDetails({
          id,
          flag: cod,
        })
      }
    },
    changeDefalutData() {
      if (this.$route.query.type === 'CASE') {
        this.breData = [
          {
            name: '法律查询',
            path: '/legal/query?type=CASE',
          },
          {
            name: '司法案例',
            path: '/legal/query?type=CASE',
          },
          {
            name: '司法案例详情页',
          },
        ]
        this.labelData = [
          {
            label: '案号',
            key: 'caseCode',
          },
          {
            label: '审理法院',
            key: 'court',
          },
          {
            label: '案件类型',
            key: 'caseType',
          },
          {
            label: '案由',
            key: 'caseReason',
          },
          {
            label: '审理程序',
            key: 'judgeProcess',
          },
          {
            label: '裁判日期',
            key: 'trialDate',
          },
          {
            label: '当事人',
            key: 'litigantName',
          },
        ]
      }
    },
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.lawyerDetails {
  width: @uni-width;
  margin: 0 auto;
  .content {
    margin-bottom: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 22px 0px rgba(142, 142, 142, 0.18);
    .title {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 80px;
      padding: 20px 20px;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      color: #333333;
      border-bottom: 1px solid #eee;
      .analysis {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .item {
      padding: 30px;
      border-bottom: 1px dashed #eee;
      .tag {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }
      .container {
        margin: 0 20px;
      }
      .uiText {
        display: flex;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        .des {
          margin-left: 10px;
          color: #333333;
        }
        .lawConntent {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 21px;
        }
        .lawText {
          text-indent: 2em;
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          line-height: 21px;
          word-wrap: break-word;
          white-space: pre-line;
          word-break: normal;
        }
      }
    }
  }
  .content1 {
    padding: 20px;
  }
  .detailsHtml {
    line-height: 30px;
  }
}
</style>
